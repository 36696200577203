<template>
    <b-modal id="generate-billing-report" size="lg" title="Generate Billing Report" ref="modal" ok-title="Generate"
        @ok="handleOk" @show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
        :no-close-on-backdrop="true">
        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

        <b-form @submit.stop.prevent="handleSubmit" novalidate>
            <b-container fluid>
                <b-row class="my-2">
                    <b-col sm="8">
                        <b>SELECT ACCOUNT</b>
                    </b-col>
                </b-row>
                <b-row class="my-2" no-gutters>
                    <b-col lg="5" md="5" sm="10" class="mr-3">
                        <b-form-group label="Asset Owner">
                            <v-select name="Asset Owner" class="style-chooser" label="text"
                                placeholder=" - Please select - " :options="allAssetOwnerOptions"
                                :reduce="(company) => company.value" v-model="selAssetOwner"
                                v-validate="'selectRequired'">
                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching">
                                        No results found for
                                        <em>
                                            <strong>{{ search }}</strong>
                                        </em>
                                    </template>
                                    <em :style="{ opacity: 0.5 }" v-else>
                                        Start typing to search for a company
                                    </em>
                                </template>
                            </v-select>
                            <span v-show="errors.has('Asset Owner')" class="help-block">
                                {{ errors.first('Asset Owner') }}
                            </span>
                        </b-form-group>
                    </b-col>
                    <b-col lg="5" md="5" sm="10">
                        <b-form-group label="Client">
                            <v-select name="Client" class="style-chooser" label="text" placeholder=" - Please select - "
                                :options="clientOptions" :reduce="(company) => company.value" v-model="selClient"
                                v-validate="'selectRequired'">

                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching">
                                        No results found for
                                        <em>
                                            <strong>{{ search }}</strong>
                                        </em>
                                    </template>
                                    <em :style="{ opacity: 0.5 }" v-else>
                                        Start typing to search for a asset owner
                                    </em>
                                </template>
                            </v-select>
                            <span v-show="errors.has('Client')" class="help-block">
                                {{ errors.first('Client') }}
                            </span>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row no-gutters>
                    <b-col lg="5" md="5" sm="10" class="mr-3">
                        <b-form-group label="Account No" description="For Active Accounts Only">
                            <v-select name="Account No" class="style-chooser" label="text"
                                placeholder=" - Please select - " :options="activeAccountOptions"
                                :reduce="(accountNo) => accountNo.value" v-model="selAccountNo"
                                v-validate="'selectRequired'">

                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching">
                                        No results found for
                                        <em>
                                            <strong>{{ search }}</strong>
                                        </em>
                                    </template>
                                    <em :style="{ opacity: 0.5 }" v-else>
                                        Start typing to search for account no
                                    </em>
                                </template>
                            </v-select>
                            <span v-show="errors.has('Account No')" class="help-block">
                                {{ errors.first('Account No') }}
                            </span>
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" md="6" sm="12" v-show="hasSelectedAccountNo">
                        <b-button id="view-account-details" variant="outline-dark" class="view-details">
                            View Account Details
                        </b-button>
                        <b-popover target="view-account-details" triggers="hover" placement="top">

                            <template #title>Account No {{ clientAccountObj.accountNo }}</template>
                            <div class="px-2">
                                <strong>Asset Owner</strong> {{ clientAccountObj.assetOwner }} <br />
                                <strong>Client</strong> {{ clientAccountObj.client }} <br />
                                <span v-show="clientAccountObj.principal && clientAccountObj.principal.length > 0">
                                    <strong>Principal</strong> {{ clientAccountObj.principal }} <br />
                                </span>
                                <strong>Contract Period</strong> {{ clientAccountObj.contractPeriod }}<br />
                                <strong>Payment Terms</strong> {{ clientAccountObj.paymentTerms }} days<br />
                                <strong>Account Manager</strong> {{ clientAccountObj.accountManager }}<br />
                                <strong>Client Manager</strong> {{ clientAccountObj.clientManager }}<br />
                            </div>
                        </b-popover>
                    </b-col>
                </b-row>

                <b-row class="my-2">
                    <b-col sm="8">
                        <b>SELECT CHARGE TYPE</b>
                    </b-col>
                </b-row>
                <b-row class="my-2" v-if="selAccountNo.id !== null" no-gutters>
                    <b-col lg="5" md="5" sm="10" class="mr-3">
                        <b-form-group label="Charge Type">
                            <v-select name="Charge Type" class="style-chooser" label="text"
                                placeholder=" - Please select - " :options="chargeTypeOptions"
                                :reduce="(chargeType) => chargeType.value" v-model="form.chargeType"
                                v-validate="'required'">

                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching">
                                        No results found for
                                        <em>
                                            <strong>{{ search }}</strong>
                                        </em>
                                    </template>
                                    <em :style="{ opacity: 0.5 }" v-else>
                                        Start typing to search for a charge type
                                    </em>
                                </template>
                            </v-select>
                            <span v-show="errors.has('Charge Type')" class="help-block">
                                {{ errors.first('Charge Type') }}
                            </span>
                        </b-form-group>
                    </b-col>
                    <b-col lg="5" md="5" sm="10" v-if="form.chargeType !== 'Trucking'">
                        <b-form-group label="Asset Type">
                            <v-select name="Asset Type" class="style-chooser" label="text"
                                placeholder=" - Please select - " :options="assetTypeOptions"
                                :reduce="(assetType) => assetType.value" v-model="selAssetType"
                                v-validate="{ 'selectRequired': assetTypeIsRequired }">

                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching">
                                        No results found for
                                        <em>
                                            <strong>{{ search }}</strong>
                                        </em>
                                    </template>
                                    <em :style="{ opacity: 0.5 }" v-else>
                                        Start typing to search for a charge type
                                    </em>
                                </template>
                            </v-select>
                            <span v-show="errors.has('Asset Type')" class="help-block">
                                {{ errors.first('Asset Type') }}
                            </span>
                        </b-form-group>
                    </b-col>
                </b-row>
                <div v-else>
                    <b-row class="my-4">
                        <b-col sm="8">
                            <span class="sub-label">No <strong>Account No</strong> selected yet.</span>
                        </b-col>
                    </b-row>
                </div>

                <div v-if="billingPeriodIsViewable">
                    <b-row class="mt-2">
                        <b-col sm="8">
                            <b>SELECT BILLING PERIOD</b>
                        </b-col>
                    </b-row>
                    <b-row class="my-2" no-gutters v-if="hasPreviousBilling">
                        <b-col lg="5" md="5" sm="10" class="mr-3">
                            <b-button id="view-previous-billing" variant="outline-dark">View Previous Billing</b-button>
                            <b-popover target="view-previous-billing" triggers="hover" placement="top-right">

                                <template #title>Previous Billing</template>
                                <strong>Start Date</strong> {{ getFormattedDate(prevBillingReportObj.startDate) }}<br />
                                <strong>End Date</strong> {{ getFormattedDate(prevBillingReportObj.endDate) }}<br />
                                <strong>Due Date</strong> {{ getFormattedDate(prevBillingReportObj.dueDate) }}<br />
                                <strong>Total VAT IN Amount</strong>
                                PHP {{ prevBillingReportObj.totalVATInclusiveAmount.toLocaleString() }}
                                <br />
                            </b-popover>
                        </b-col>
                    </b-row>
                    <b-row class="mt-4 mb-2" no-gutters>
                        <b-col lg="5" md="5" sm="10" class="mr-3">
                            <b-form-group label="Start Date">
                                <b-form-datepicker name="Start Date" v-model="selStartDate" locale="en" reset-button
                                    label-reset-button="Clear" :date-format-options="dateFormatOptions"
                                    v-validate="'required'" readonly />
                                <span v-show="errors.has('Start Date')" class="help-block">
                                    {{ errors.first('Start Date') }}
                                </span>
                            </b-form-group>
                        </b-col>
                        <b-col lg="5" md="5" sm="10">
                            <b-form-group label="End Date">
                                <b-form-datepicker name="End Date" v-model="selEndDate" locale="en" reset-button
                                    label-reset-button="Clear" :date-format-options="dateFormatOptions"
                                    v-validate="'required'" />
                                <span v-show="errors.has('End Date')" class="help-block">
                                    {{ errors.first('End Date') }}
                                </span>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row no-gutters>
                        <b-col lg="5" md="5" sm="10" class="mr-3">
                            <b-form-group label="Due Date" :description="paymentTermDescription">
                                <b-form-datepicker name="Due Date" v-model="selDueDate" locale="en" reset-button
                                    label-reset-button="Clear" :date-format-options="dateFormatOptions"
                                    v-validate="'required'" />
                                <span v-show="errors.has('Due Date')" class="help-block">
                                    {{ errors.first('Due Date') }}
                                </span>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>

            </b-container>
        </b-form>
    </b-modal>
</template>

<script>
// Utils
import { BillingReportUtil } from '@/utils/billingReportUtil';
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

// API & DAO
import billingReportApi from '@/api/billingReportApi';
import billingReportDAO from '@/database/billingReports';

// Others
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
    name: "generate-billing-report",
    components: {
        Loading
    },
    props: {
        allAssetOwnerOptions: {
            type: Array,
            required: true
        },
        allClientOptions: {
            type: Array,
            required: true
        },
        allClientAccountsObj: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            form: { ...BillingReportUtil.getDefaultBillingReportObj() },

            dateFormatOptions: { ...config.dateFormatOptions },
            selAssetOwner: { ...config.companyDefaultValue },
            selClient: { ...config.companyDefaultValue },
            selAccountNo: { ...config.clientAccountDefaultValue },
            selAssetType: { ...config.assetTypeDefaultValue },
            selStartDate: null,
            selEndDate: null,
            selDueDate: null,

            clientOptions: [],
            activeAccountOptions: [],
            chargeTypeOptions: [],
            assetTypeOptions: [],

            clientAccountObj: {},
            prevBillingReportObj: {},

            isSuperAdmin: this.$store.getters.isSuperAdmin,
            loggedUser: this.$store.getters.loggedUser,
            loggedUserCompany: this.$store.getters.loggedUserCompany,
            // Check for loader
            isLoading: false,
        }
    },
    computed: {
        disableConfirmButtons() {
            return this.isLoading;
        },
        hasSelectedAccountNo() {
            return this.selAccountNo && this.selAccountNo.accountNo && this.selAccountNo.accountNo.length > 0;
        },
        assetTypeIsRequired() {
            return this.form.chargeType && this.form.chargeType !== 'Trucking';
        },
        billingPeriodIsViewable() {
            return this.form.chargeType && (this.form.chargeType === 'Trucking' || this.selAssetType.assetTypeId);
        },
        hasPreviousBilling() {
            return !_.isEmpty(this.prevBillingReportObj);
        },
        paymentTermDescription() {
            return "Payment Term as set to " + this.clientAccountObj.paymentTerms + " days";
        }
    },
    watch: {
        selAssetOwner: function (newVal) {
            if (newVal && newVal.id) {

                // remove selected asset owner from client options
                this.clientOptions = _.filter(this.allClientOptions, o => {
                    return o.value.id !== newVal.id;
                });

                this.initClientAccountOptions();
            }
        },
        selClient: function (newVal) {
            if (newVal && newVal.id) {
                this.initClientAccountOptions();
            }
        },
        selAccountNo: function (newVal) {
            if (newVal && newVal.id) {
                // Get the client account object from cache
                this.clientAccountObj = this.allClientAccountsObj[newVal.id];
                this.initChargeTypeOptions();
            }
        },
        'form.chargeType': async function (newVal) {
            if (newVal && newVal.length > 0) {
                await this.initChargeTypeDetails();
            }
        },
        selAssetType: async function (newVal) {
            if (newVal && newVal.assetTypeId) {
                await this.updateAssetTypeChargeDetails();
            }
        },
        selEndDate: function (newVal) {
            if (newVal) {
                this.form.endDate = new Date(newVal).getTime();
                this.adjustDueDate(newVal);
            }
        },
    },
    mounted() {
        this.clientOptions = [...this.allClientOptions];
    },
    methods: {
        initClientAccountOptions() {
            let assetOwnerId = this.selAssetOwner && this.selAssetOwner.id ? this.selAssetOwner.id : "";
            let clientId = this.selClient && this.selClient.id ? this.selClient.id : "";
            let filteredAccountsObj = _.filter(this.allClientAccountsObj, o => {
                return (assetOwnerId.length === 0 || assetOwnerId === o.assetOwnerId)
                    && (clientId.length === 0 || clientId === o.clientId);
            });

            this.activeAccountOptions = DropDownItemsUtil.retrieveClientAccounts(filteredAccountsObj, true);
        },
        initChargeTypeOptions() {
            this.chargeTypeOptions = DropDownItemsUtil.retrieveChargeTypes([this.clientAccountObj]);

            // Set to default value
            this.form.chargeType = null;
        },
        async initChargeTypeDetails() {
            let chargeTypeDetails = {};

            if (this.form.chargeType === 'Rental') {
                chargeTypeDetails = {
                    assetType: '',
                    assetTypeId: '',
                    rentRate: 0,
                    rentFrequency: '',
                    currency: 'PHP',
                    quantity: 0,
                    description: ''
                }

                this.initAssetTypeOptions();

            } else if (this.form.chargeType === 'Trucking') {
                chargeTypeDetails = {
                    description: 'Trucking Cost',
                    currency: 'PHP',
                }

                // Proceed with Billing Period Details
                await this.initBillingPeriodDetails();
            } else {
                // Other Billable Rates from Asset Type Conditions
                chargeTypeDetails = {
                    assetType: '',
                    assetTypeId: '',
                    vatExRate: 0,
                    currency: 'PHP',
                    description: ''
                }

                this.initAssetTypeOptions();
            }

            this.form.chargeTypeDetails = chargeTypeDetails;
        },
        initAssetTypeOptions() {
            this.assetTypeOptions = DropDownItemsUtil.retrieveChargeTypeAssetTypes(this.clientAccountObj, this.form.chargeType);

            // Set to default value
            this.selAssetType = { ...config.assetTypeDefaultValue };
        },
        async updateAssetTypeChargeDetails() {
            let chargeTypeDetails = {};

            if (this.form.chargeType === 'Rental') {
                chargeTypeDetails = {
                    assetType: this.selAssetType.assetType,
                    assetTypeId: this.selAssetType.assetTypeId,
                    rentRate: parseFloat(this.selAssetType.rentRate),
                    rentFrequency: this.selAssetType.rentFrequency,
                    currency: this.selAssetType.rentCurrency,
                    quantity: this.selAssetType.quantity,
                    description: 'For Rental',
                    startingQuantity: 0,
                    endingQuantity: 0
                }

                if (chargeTypeDetails.rentFrequency === 'Per Day') {
                    chargeTypeDetails.startingDays = 0;
                    chargeTypeDetails.endingDays = 0;
                } else {
                    chargeTypeDetails.startingHours = 0;
                    chargeTypeDetails.endingHours = 0;
                }

            } else {
                let billableRate = _.find(this.selAssetType.billableRates, o => {
                    return o.condition === this.form.chargeType;
                });
                chargeTypeDetails = {
                    assetType: this.selAssetType.assetType,
                    assetTypeId: this.selAssetType.assetTypeId,
                    vatExclusiveRate: parseFloat(billableRate.vatExclusiveRate),
                    currency: this.selAssetType.rentCurrency,
                    description: billableRate.description
                }
            }

            this.form.chargeTypeDetails = chargeTypeDetails;

            // Proceed with Billing Period Details
            await this.initBillingPeriodDetails();
        },

        async initBillingPeriodDetails() {
            let prevBillingReportsObj = await billingReportDAO.getPreviousBillingReports(this.clientAccountObj.id, this.form.chargeType, ['Approved'], 1);
            let prevBillingReportsArr = Object.values(prevBillingReportsObj);
            this.prevBillingReportObj = prevBillingReportsArr[0];

            if (_.isEmpty(this.prevBillingReportObj)) {
                // START DATE
                this.selStartDate = new Date(this.clientAccountObj.startDate);

                // END DATE
                if (this.clientAccountObj.billingFrequency === 'Daily') {
                    let startDateTimeStamp = DateUtil.startDateTimeStamp(this.selStartDate);
                    this.selEndDate = new Date(startDateTimeStamp);
                    this.selEndDate = new Date(this.selEndDate.setDate(this.selEndDate.getDate() + 1));

                } else if (this.clientAccountObj.billingFrequency === 'Monthly') {
                    let startDateTimeStamp = DateUtil.startDateTimeStamp(this.selStartDate);
                    this.selEndDate = new Date(startDateTimeStamp);
                    this.selEndDate = new Date(this.selEndDate.setDate(this.clientAccountObj.billingSchedule));

                    if (this.selEndDate < this.selStartDate) {
                        // Move to next Month
                        let currMonth = this.selEndDate.getMonth() + 1;
                        this.selEndDate = new Date(this.selEndDate.setMonth(currMonth));
                    }

                } else if (this.clientAccountObj.billingFrequency === 'Yearly') {
                    let startDateTimeStamp = DateUtil.startDateTimeStamp(this.selStartDate);
                    this.selEndDate = new Date(startDateTimeStamp);
                    this.selEndDate = new Date(this.selEndDate.setFullYear(this.selEndDate.getFullYear() + 1));
                }

            } else {
                // START DATE
                let prevEndDate = new Date(this.prevBillingReportObj.endDate);
                this.selStartDate = new Date(prevEndDate.setDate(prevEndDate.getDate() + 1));

                // END DATE
                if (this.clientAccountObj.billingFrequency === 'Daily') {
                    let startDateTimeStamp = DateUtil.startDateTimeStamp(this.selStartDate);
                    this.selEndDate = new Date(startDateTimeStamp);
                    this.selEndDate = new Date(this.selEndDate.setDate(this.selEndDate.getDate() + 1));

                } else if (this.clientAccountObj.billingFrequency === 'Monthly') {
                    let startDateTimeStamp = DateUtil.startDateTimeStamp(this.selStartDate);
                    this.selEndDate = new Date(startDateTimeStamp);
                    this.selEndDate = new Date(this.selEndDate.setDate(this.clientAccountObj.billingSchedule));

                    if (this.selEndDate < this.selStartDate) {
                        // Move to next Month
                        let currMonth = this.selEndDate.getMonth() + 1;
                        this.selEndDate = new Date(this.selEndDate.setMonth(currMonth));
                    }

                } else if (this.clientAccountObj.billingFrequency === 'Yearly') {
                    let startDateTimeStamp = DateUtil.startDateTimeStamp(this.selStartDate);
                    this.selEndDate = new Date(startDateTimeStamp);
                    this.selEndDate = new Date(this.selEndDate.setFullYear(this.selEndDate.getFullYear() + 1));
                }
            }

            // DUE DATE
            this.adjustDueDate(this.selEndDate);
        },
        adjustDueDate(endDate) {
            let paymentTerms = this.clientAccountObj.paymentTerms ? parseInt(this.clientAccountObj.paymentTerms) : 0;
            let endDateTimeStamp = DateUtil.startDateTimeStamp(endDate);
            this.selDueDate = new Date(endDateTimeStamp);
            this.selDueDate = new Date(this.selDueDate.setDate(this.selDueDate.getDate() + paymentTerms));
        },

        getNextBillingNumber(prevReportObj, clientAccount) {
            let count = 1;

            if (prevReportObj && !_.isEmpty(prevReportObj)) {
                let parts = prevReportObj.billingNumber.split("/");
                count = parseInt(parts[0]);
                count++;
            }

            return count + "/" + clientAccount.totalBillings;
        },

        async handleOk(evt) {
            // Prevent modal from closing
            evt.preventDefault();

            // show loading indicator
            this.isLoading = true;

            let isValid = await this.$validator.validateAll();
            if (!isValid) {
                this.$toaster.warning('Please address the field/s with invalid input.');
                this.isLoading = false;
                return;
            }

            if (!this.validateBillingReport()) {
                this.isLoading = false;
                return;
            }

            await this.handleSubmit();
        },
        validateBillingReport() {
            let isValid = true;

            // Validate Primary Details
            if (_.isEmpty(this.selAssetOwner) || !this.selAssetOwner.id) {
                this.$toaster.warning('No selected asset provider');
                isValid = false;
            } else if (_.isEmpty(this.selClient) || !this.selClient.id) {
                this.$toaster.warning('No selected client');
                isValid = false;
            } else if (_.isEmpty(this.selAccountNo) || !this.selAccountNo.id) {
                this.$toaster.warning('No selected account no');
                isValid = false;
            } else {
                // Validate Billing Period Details
                let startDate = DateUtil.startDateTimeStamp(new Date(this.selStartDate));
                let endDate = DateUtil.endDateTimeStamp(new Date(this.selEndDate));
                let dueDate = DateUtil.endDateTimeStamp(new Date(this.selDueDate));
                if (startDate >= endDate) {
                    this.$toaster.warning('Start Date must be before End Date.');
                    isValid = false;
                } else if (startDate > dueDate) {
                    this.$toaster.warning('Start Date must be before Due Date.');
                    isValid = false;
                } else if (endDate > dueDate) {
                    this.$toaster.warning('End Date must be before Due Date.');
                    isValid = false;
                }
            }

            return isValid;
        },

        getBillingReportObj() {
            this.form.billingReportNo = 'BR' + DateUtil.getCurrentTimestamp();
            this.form.billingNumber = '';

            // Asset Owner
            this.form.assetOwnerId = this.selAssetOwner.id;
            this.form.assetOwner = this.selAssetOwner.name;

            // Client
            this.form.clientId = this.selClient.id;
            this.form.client = this.selClient.name;

            // Client Account
            this.form.clientAccountId = this.selAccountNo.id;
            this.form.clientAccountNo = this.selAccountNo.accountNo;

            // Billing Period
            this.form.startDate = DateUtil.startDateTimeStamp(new Date(this.selStartDate));
            this.form.endDate = DateUtil.endDateTimeStamp(new Date(this.selEndDate));
            this.form.dueDate = DateUtil.endDateTimeStamp(new Date(this.selDueDate));
            this.form.billingNumber = this.getNextBillingNumber(this.prevBillingReportObj, this.clientAccountObj);

            this.form.status = 'Pending';

            // Timestamp
            this.form.dateCreated = DateUtil.getCurrentTimestamp();
            this.form.createdBy = this.loggedUser.id;
            this.form.dateUpdated = DateUtil.getCurrentTimestamp();
            this.form.updatedBy = this.loggedUser.id;

            return BillingReportUtil.cleanupFields(this.form);
        },
        async handleSubmit() {
            // show loading indicator
            this.isLoading = true;

            let reportObj = this.getBillingReportObj();

            try {
                let { data } = await billingReportApi.saveBillingReport(
                    reportObj,
                    this.loggedUser.id,
                    DateUtil.getCurrentTimestamp()
                );

                if (data.isSuccess) {
                    this.$toaster.success(`Billing Report "${reportObj.billingReportNo}" was created successfully.`);
                    this.$refs.modal.hide();

                    // Redirect to Edit Billing Report
                    this.redirectToEditBillingReport(data.billingReport);

                } else {
                    this.$toaster.error(`Error creating billing report "${reportObj.billingReportNo}". Please try again.`);
                }

            } catch (error) {
                this.$toaster.error(`Error creating billing report "${reportObj.billingReportNo}". Please try again.`);
            }

            // hide loading indicator
            this.isLoading = false;
        },
        redirectToEditBillingReport(billingReport) {
            this.$store.commit('SET_CURR_BILLING_REPORT', billingReport);

            if (this.isSuperAdmin) {
                this.$router.push({ path: '/admin/admin-edit-billing-report' });
            } else {
                this.$router.push({ path: '/edit-billing-report' });
            }
        },

        onReset() {
            this.form = { ...BillingReportUtil.getDefaultBillingReportObj() };

            this.selAssetOwner = { ...config.companyDefaultValue };
            this.selClient = { ...config.companyDefaultValue };
            this.selAccountNo = { ...config.clientAccountDefaultValue };
            this.selAssetType = { ...config.assetTypeDefaultValue };

            this.initClientAccountOptions();
            this.chargeTypeOptions = [' - Please select - '];
            this.assetTypeOptions = [' - Please select - '];

            this.clientAccountObj = {};

            // reset validation
            this.isLoading = false;
            this.$validator.reset();
            this.errors.clear();
        },

        // UTILS
        getFormattedDate(date) {
            return DateUtil.getFormattedDate(date);
        }
    }
}
</script>

<style scoped>
.sub-label {
    font-style: italic;
    color: #73818f;
}

.view-details {
    margin-top: 32px;
}
</style>